import BigNumber from 'bignumber.js'
import { ethers } from 'ethers'
import { BIG_ZERO, DEFAULT_TOKEN_DECIMAL } from './formatBalance'

export const approve = async (lpContract, masterChefContract, account) => {
  return lpContract.methods
    .approve(masterChefContract.options.address, ethers.constants.MaxUint256)
    .send({ from: account })
}

export const stake = async (masterChefContract, pid, amount, account, referralValue) => {
  return masterChefContract.methods
    .deposit(pid, new BigNumber(amount).times(DEFAULT_TOKEN_DECIMAL).toString(), referralValue)
    .send({ from: account })
    .on('transactionHash', (tx) => {
      // console.log(tx)
      return tx.transactionHash
    })
}

export const swap = async (masterChefContract, account, from, to, amount) => {
  return masterChefContract.methods
    .swap(from, to, new BigNumber(amount).times(DEFAULT_TOKEN_DECIMAL).toString())
    .send({ from: account })
    .on('transactionHash', (tx) => {
      // console.log(tx)
      return tx.transactionHash
    })
}

export const stakeWithMint = async (masterChefContract, pid,
                                    amountBUSD,amountUSDC,amountUSDT,amountDAI, account) => {
  return masterChefContract.methods
    .depositWithMint(pid,
      new BigNumber(amountBUSD).times(DEFAULT_TOKEN_DECIMAL).toString(),
      new BigNumber(amountUSDC).times(DEFAULT_TOKEN_DECIMAL).toString(),
      new BigNumber(amountUSDT).times(DEFAULT_TOKEN_DECIMAL).toString(),
      new BigNumber(amountDAI).times(DEFAULT_TOKEN_DECIMAL).toString(),
    )
    .send({ from: account })
    .on('transactionHash', (tx) => {
      // console.log(tx)
      return tx.transactionHash
    })
}

export const sousStake = async (sousChefContract, amount, account) => {
  return sousChefContract.methods
    .deposit(new BigNumber(amount).times(DEFAULT_TOKEN_DECIMAL).toString())
    .send({ from: account, gas: 500000 })
    .on('transactionHash', (tx) => {
      return tx.transactionHash
    })
}

export const sousStakeBnb = async (sousChefContract, amount, account) => {
  return sousChefContract.methods
    .deposit()
    .send({
      from: account,
      gas: 500000,
      value: new BigNumber(amount).times(DEFAULT_TOKEN_DECIMAL).toString(),
    })
    .on('transactionHash', (tx) => {
      return tx.transactionHash
    })
}

export const unstake = async (masterChefContract, pid, amount, account) => {
  return masterChefContract.methods
    .withdraw(pid, new BigNumber(amount).times(DEFAULT_TOKEN_DECIMAL).toString())
    .send({ from: account, gas: 5000000 })
    .on('transactionHash', (tx) => {
      return tx.transactionHash
    })
}

export const unstakeWithBurn = async (masterChefContract, pid,
                                      amountBUSD,amountUSDC,amountUSDT,amountDAI, account) => {
  return masterChefContract.methods
    .withdrawWithBurn(pid,
      new BigNumber(amountBUSD).times(DEFAULT_TOKEN_DECIMAL).toString(),
      new BigNumber(amountUSDC).times(DEFAULT_TOKEN_DECIMAL).toString(),
      new BigNumber(amountUSDT).times(DEFAULT_TOKEN_DECIMAL).toString(),
      new BigNumber(amountDAI).times(DEFAULT_TOKEN_DECIMAL).toString())
    .send({ from: account, gas: 500000 })
    .on('transactionHash', (tx) => {
      return tx.transactionHash
    })
}

export const sousUnstake = async (sousChefContract, amount, account) => {
  // shit code: hard fix for old CTK and BLK
  if (sousChefContract.options.address === '0x3B9B74f48E89Ebd8b45a53444327013a2308A9BC') {
    return sousChefContract.methods
      .emergencyWithdraw()
      .send({ from: account })
      .on('transactionHash', (tx) => {
        return tx.transactionHash
      })
  }
  if (sousChefContract.options.address === '0xBb2B66a2c7C2fFFB06EA60BeaD69741b3f5BF831') {
    return sousChefContract.methods
      .emergencyWithdraw()
      .send({ from: account })
      .on('transactionHash', (tx) => {
        return tx.transactionHash
      })
  }

  return sousChefContract.methods
    .withdraw(new BigNumber(amount).times(DEFAULT_TOKEN_DECIMAL).toString())
    .send({ from: account, gas: 500000 })
    .on('transactionHash', (tx) => {
      return tx.transactionHash
    })
}

export const sousEmegencyUnstake = async (sousChefContract, amount, account) => {
  return sousChefContract.methods
    .emergencyWithdraw()
    .send({ from: account })
    .on('transactionHash', (tx) => {
      return tx.transactionHash
    })
}

export const harvest = async (masterChefContract, pid, account) => {
  return masterChefContract.methods
    .deposit(pid, '0', "0x381e583B4EfA91c347D84C6C8369151411a50676")
    .send({ from: account, gas: 5000000 })
    .on('transactionHash', (tx) => {
      return tx.transactionHash
    })
}

export const harvestWithdraw = async (masterChefContract, pid, account) => {
  return masterChefContract.methods
    .withdraw(pid, '0')
    .send({ from: account, gas: 500000 })
    .on('transactionHash', (tx) => {
      return tx.transactionHash
    })
}

export const soushHarvest = async (sousChefContract, account) => {
  return sousChefContract.methods
    .deposit('0')
    .send({ from: account, gas: 500000 })
    .on('transactionHash', (tx) => {
      return tx.transactionHash
    })
}

export const soushHarvestBnb = async (sousChefContract, account) => {
  return sousChefContract.methods
    .deposit()
    .send({ from: account, gas: 500000, value: BIG_ZERO })
    .on('transactionHash', (tx) => {
      return tx.transactionHash
    })
}

export const buyERC721FromMarket = async (masterchefContract, account, itemId) => {
  return masterchefContract.methods
    .buyERC721FromMarket(itemId)
    .send({ from: account, value: BIG_ZERO })
    .on('transactionHash', (tx) => {
      return tx.transactionHash
    })
}

export const approveERC721 = async (lpContract, masterChefContract, account) => {
  return lpContract.methods.setApprovalForAll(masterChefContract.options.address, 1).send({ from: account })
}

export const placeERC721onMarket = async (
  masterchefContract,
  account,
  collection,
  tokenId,
  price,
  poolToken,
  forSale,
) => {
  return masterchefContract.methods
    .placeERC721OnMarket(
      collection,
      tokenId,
      new BigNumber(price).times(DEFAULT_TOKEN_DECIMAL).toString(),
      poolToken,
      forSale,
    )
    .send({ from: account, value: BIG_ZERO })
    .on('transactionHash', (tx) => {
      return tx.transactionHash
    })
}
